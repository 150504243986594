















































import { Component, Inject, Mixins, Prop, PropSync, Ref } from 'vue-property-decorator'

import { AllowedImageRatio, AllowedImageWidth, ProductData } from '../../../../../contexts'

import { AddedToCartModalMixin } from '../../../../shared/mixins/addedToCartModal.mixin'
import { CartItem } from '../../../../checkout/molecules/CartItem'
import { Loader } from '../../../../shared/molecules/Loader'
import { toImageProps } from '../../../../shared/support'
import { ProductCard } from '../../../../products/organisms/ProductCard'
import {
  translateProductToProductCard
} from '../../../../products/organisms/ProductCard/ProductCard.helpers'

import { CartItemProps } from '../../../molecules/CartItem/CartItem.contracts'
import { defaultProvider, IS_MOBILE_PROVIDER_KEY } from '../../../../../support'
import { DynamicContentData } from '../../../../shared/contracts/dynamicContent'
import { DynamicContentAction } from '../../../../analytics/events/dynamicContent.event'
import { v4 as uuid } from 'uuid'
import { DynamicHelperMixin } from '../../../../shared/mixins/dynamicHelper.mixin'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<Rich>({
  name: 'Rich',
  components: {
    CartItem,
    Loader,
    ProductCard
  },
  updated () {
    if (this.addedToCartModalSuggestedRef) {
      this._suggestedProductsRef = this.addedToCartModalSuggestedRef
    }
  }
})
export class Rich extends Mixins(AddedToCartModalMixin, DynamicHelperMixin) {
  @Inject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Prop({ type: Boolean, required: true })
  public isLoading!: boolean

  @Prop({ type: Array, required: true, default: [] })
  public suggestedProducts!: Array<ProductData>

  @Prop({ type: Object, required: false, default: null })
  public readonly dynamicContentPayload!: DynamicContentData | null

  @PropSync('suggestedProductsRef', { type: HTMLDivElement, required: false, default: null })
  public _suggestedProductsRef!: HTMLElement | null

  @Ref('addedToCartModalSuggested')
  public addedToCartModalSuggestedRef!: HTMLElement | null

  public get image () {
    return toImageProps(this.payload.product.image, AllowedImageRatio.Square, AllowedImageWidth.ProductCardSmall)
  }

  public get elementId (): string {
    return uuid()
  }

  public onProductCardClick (): void {
    if (this.dynamicContentPayload) {
      this.triggerDynamicContentEvent(this.dynamicContentPayload, DynamicContentAction.Click)
    }
    this.close()
  }

  /**
   * Determines whether product is in some set.
   */
  public get inBundles (): boolean {
    return Array.isArray(this.payload.product.bundledSiblings) && this.payload.product.bundledSiblings.length > 0
  }

  public get productItem (): CartItemProps {
    return {
      description: this.payload.product.title ?? '',
      price: this.payload.product.price,
      previousPrice: this.payload.product.previousPrice,
      thumbnail: this.image,
      title: this.payload.product.brand ?? '',
      enhancedDesc: `${this.payload.product.category ?? ''} ${this.payload.product.volume ?? ''}`,
      properties: [],
      isDisabled: true
    }
  }

  public get shownProducts (): Array<ProductData> {
    if (this.isMobile()) {
      return this.suggestedProducts.slice(0, 2)
    }

    return this.suggestedProducts.slice(0, 4)
  }

  public translateProductToProductCard = translateProductToProductCard
}

export default Rich
